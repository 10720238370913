import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react'
import queryString from 'query-string'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { IMPORT_MAP } from '@app/importMap'

import {
  INSURANCE_AMOUNT,
  INSURANCE_NAME,
  INSURANCE_WEBSITE,
  PROPERTY_INSURANCE_AMOUNT,
  PROPERTY_INSURANCE_NAME,
  PROPERTY_INSURANCE_WEBSITE,
} from '@app/constants/Insurance'
import { APP_INFO, EXTERNAL_LINKS_REL, FOUNDING_YEAR } from '@app/constants/Misc'
import { PRESS_ARTICLES } from '@app/constants/Press'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { ensureType } from '@app/utils/ensureType'
import { assertApiActionResponse, WithFetchData } from '@app/utils/performFetchData'
import { defineFormatters } from '@app/utils/reactIntl'
import { useAppDispatch } from '@app/utils/redux'
import { useRouter } from '@app/utils/routing/hooks'
import { Link } from '@app/utils/routing/Link'
import { RouteConfigComponentProps } from '@app/utils/routing/types'

import { useSetMeta } from '@app/hooks/useSetMeta'

import { getSectionFaqTask } from '@app/store/actions/faq'
import { getMount, getState, getStatistics, registerPreload, withProgress } from '@app/store/actions/initial'
import { updateTaskValue } from '@app/store/actions/tasks'
import { getTbankReviewsSummary } from '@app/store/actions/tbankReviews'
import { landingVersionSelector } from '@app/store/selectors/layout'
import { intlSelector, statisticsSelector } from '@app/store/selectors/misc'
import { profileRegionSelector } from '@app/store/selectors/profile'
import { sessionSelector } from '@app/store/selectors/session'
import { tbankReviewsMetaSelector } from '@app/store/selectors/tbankReviews'
import { StoreState } from '@app/store/store'

import { AccordionStyleFAQ } from '@app/routes/Static/components/LandingJuly/AccordionStyleFAQ'
import { ButtonTypePrimary } from '@app/routes/Static/components/LandingJuly/ButtonType'
import { useLandingGallery } from '@app/routes/Static/components/LandingJuly/LandingGallery'

import { Button } from '@app/components/Button/Button'
import { FaqAccordion } from '@app/components/FaqAccordion/FaqAccordion'
import { InlineIcon } from '@app/components/Icon/Icon'
import { IntoView } from '@app/components/IntoView/IntoView'
import { Spacer } from '@app/components/Layout/Spacer'
import { LayoutMilk } from '@app/components/LayoutMilk/LayoutMilk'
import { Typography } from '@app/components/Typography/Typography'

import { LandingData } from './landingData'
import { useSitterGallery } from './SitterGallery'

import landing from '../LandingJuly/LandingJuly.module.scss'
import typo from '../LandingJuly/Typography.module.scss'
import classes from './LandingParent.module.scss'

export const LandingParent: FunctionComponent<RouteConfigComponentProps> & WithFetchData = ({ location }) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const boundVersionSelector = useCallback(
    (state: StoreState) => versionDataSelector(state, { location: { pathname: location.pathname } }),
    [location.pathname]
  )
  const boundMetaSelector = useCallback((state: StoreState) => metaSelector(state, { location: { pathname: location.pathname } }), [location.pathname])
  const meta = useSelector(boundMetaSelector)
  const versionData = useSelector(boundVersionSelector)
  const statistics = useSelector(statisticsSelector)
  const tbankReviewMeta = useSelector(tbankReviewsMetaSelector)
  const sitterGallery = useSitterGallery()
  const questions = (() => {
    const task = dispatch(getSectionFaqTask('parents/landing'))
    if (!task.loadedAt.value) throw new Error("Faq task hasn't been executed")
    return task.value.value?.data.attributes.questions ?? []
  })()
  const pressGallery = useLandingGallery({
    contentClassname: classes.press_gallery_content,
    items: (() => {
      const multipliers = {
        vc: 0.7,
        nen: 0.9,
        novaya: 0.8,
        theoryandpractice: 1.6,
        afisha: 1.4,
      }

      return PRESS_ARTICLES.map(a => {
        const base = 170 / a.logo_size[0]
        const mlt = multipliers[a.id] ?? 1
        return {
          id: a.id,
          node: (
            <a href={a.link} key={a.id} rel={EXTERNAL_LINKS_REL} target="_blank">
              <img
                alt={a.title}
                className={classes.press_item_image}
                src={a.logo}
                style={{ width: `${a.logo_size[0] * base * mlt}px`, height: `${a.logo_size[1] * base * mlt}px` }}
              />
            </a>
          ),
        }
      })
    })(),
  })

  useSetMeta(meta)
  useShouldLogin()

  const blueBlock = (
    <div className={cn(classes.blue_block, classes.top_block)}>
      <h1 className={typo.landing_h1}>{versionData.title}</h1>
      <p className={cn(typo.text, classes.blue_block_tags)}>{versionData.tags.map(i => `#${i}`).join(' ')}</p>
      <p className={cn(typo.text, classes.blue_block_stats)}>
        {formatMessage(messages.completed_requests_since, { completed: statistics.fulfilled_requests, year: FOUNDING_YEAR })}
      </p>
    </div>
  )

  const titleImage = <div className={cn(classes.title_image, classes.top_image)} />

  const renderButton = useCallback((type: 'green' | 'orange', className: string, href: string, label: string) => {
    return (
      <a className={cn(classes.title_button, classes[`m_${type}`], className)} href={href}>
        {label}
        <InlineIcon className={classes.title_button_arrow} icon="arrow-right" />
      </a>
    )
  }, [])

  const renderActionButton = useCallback(
    ({ section }: { section: string }) => {
      return (
        <IntoView>
          <div className={Typography.Centered}>
            <Button component={Link} eventName="landing_parent.find_sitter_button" eventParams={{ section }} to="/search" type={ButtonTypePrimary.instance}>
              {formatMessage(messages.find_a_sitter)}
            </Button>
          </div>
        </IntoView>
      )
    },
    [formatMessage]
  )

  const searchButton = renderButton('green', classes.top_search, '/search', formatMessage(messages.find_a_sitter))
  const academyButton = renderButton('orange', classes.top_online, '/academy', formatMessage(messages.online_lessons))
  const tbankRating = Math.round(tbankReviewMeta.avg_rating! * 100) / 100

  const tbankReviewBlock = (
    <section aria-label={formatMessage(messages.rating_based_on_tbank, { rating: tbankRating })} className={cn(landing.section, classes.tbank)}>
      <div className={classes.tbank_rating}>
        {tbankRating} <img alt={formatMessage(messages.rating)} src={require('../../assets/rating-star.svg?force_file')} />
      </div>{' '}
      <span className={typo.text}>{formatMessage(messages.based_on, { count: tbankReviewMeta.total_count ?? 0 })}</span>
      <img alt={formatMessage(messages.of_tbank)} src={require('../../assets/tbank.svg')} />
      <a className={classes.overlay_link} href="https://www.tbank.ru/reviews/company/kidsout/18418/" rel={EXTERNAL_LINKS_REL} target="_blank">
        {' '}
      </a>
    </section>
  )

  const howItWorksBlock = (
    <section className={cn(landing.section)}>
      <h2 className={cn(landing.section_title)}>{formatMessage(messages.how_it_works)}</h2>
      <Spacer height="2unit" />
      <div className={classes.how_it_works_items}>
        {ensureType<
          {
            id: string
            image: string
            title: string
            description: string
          }[]
        >([
          {
            id: 'search',
            image: require('../../assets/icons84/list.svg?force_file'),
            title: formatMessage(messages.sitter_search),
            description: formatMessage(messages.choose_a_profile),
          },
          {
            id: 'chat',
            image: require('../../assets/icons84/chat.svg?force_file'),
            title: formatMessage(messages.making_an_order),
            description: formatMessage(messages.discuss_in_chat),
          },
          {
            id: 'order',
            image: require('../../assets/icons84/order.svg?force_file'),
            title: formatMessage(messages.order),
            description: formatMessage(messages.child_passing_time_with_fun),
          },
          {
            id: 'payment',
            image: require('../../assets/icons84/card.svg?force_file'),
            title: formatMessage(messages.payment),
            description: formatMessage(messages.only_after_the_order),
          },
        ]).map(i => (
          <div className={classes.how_it_works_item} key={i.id}>
            <img alt="" className={classes.how_it_works_item_image} src={i.image} />
            <h3 className={cn(typo.landing_h3, classes.how_it_works_item_title)}>{i.title}</h3>
            <p className={cn(typo.subtext, classes.how_it_works_item_text)}>{i.description}</p>
          </div>
        ))}
      </div>
    </section>
  )

  const appData = useMemo(
    () =>
      ensureType<{ image: string; alt: string; url: string; eventName: string }[]>([
        {
          image: require('../../assets/store_banners/play_store.svg?force_file'),
          alt: 'Google Play',
          url: APP_INFO.parent.android.url,
          eventName: 'landing_parent.app_promotion.play_store_button',
        },
        {
          image: require('../../assets/store_banners/galaxy_store.svg?force_file'),
          alt: 'Samsung Galaxy Store',
          url: APP_INFO.parent.galaxy.url,
          eventName: 'landing_parent.app_promotion.galaxy_button',
        },
        {
          image: require('../../assets/store_banners/app_store.svg?force_file'),
          alt: 'App Store',
          url: APP_INFO.parent.ios.url,
          eventName: 'landing_parent.app_promotion.app_store_button',
        },
      ]),
    []
  )

  const downloadAppBlock = (
    <section className={cn(landing.section)}>
      <div className={classes.download_app_items}>
        {appData.map(b => (
          <Link eventName={b.eventName} external={true} key={b.alt} rel={EXTERNAL_LINKS_REL} target="_blank" to={b.url}>
            <div className={classes.download_app_item}>
              <img alt={b.alt} src={b.image} />
            </div>
          </Link>
        ))}
      </div>
      <Spacer height="1unit" />
      <p className={cn(typo.text, classes.download_app_apk)}>
        <FormattedMessage
          {...messages.also_apk}
          values={defineFormatters({
            apk: msgs => (
              <a href={APP_INFO.parent.apk.url} target="_blank">
                {msgs}
              </a>
            ),
          })}
        />
      </p>
    </section>
  )

  const benefitsBlock = (
    <section className={cn(landing.section)}>
      <h2 className={cn(landing.section_title)}>{formatMessage(messages.benefits)}</h2>
      <Spacer height="2unit" />
      <IntoView>
        <div className={classes.benefits}>
          {ensureType<{ id: string; image?: string; image_anchor?: 'top' | 'bottom'; title: string; description: ReactNode }[]>([
            {
              id: 'direct',
              title: formatMessage(messages.agreements_without_intermediates),
              description: formatMessage(messages.discuss_directly),
              image: require('../../assets/benefits/mercury.svg?force_file'),
              image_anchor: 'bottom',
            },
            {
              id: 'reliable',
              title: formatMessage(messages.reliable_sitters),
              description: formatMessage(messages.all_sitters_are_trained),
            },
            {
              id: 'stats',
              title: formatMessage(messages.work_statistics),
              description: formatMessage(messages.statistics_are_kept),
              image: require('../../assets/benefits/tasman.svg?force_file'),
              image_anchor: 'top',
            },
            {
              id: 'payment',
              title: formatMessage(messages.payment_on_completion),
              description: formatMessage(messages.you_pay_only_after_completion),
            },
            {
              id: 'insurance',
              title: formatMessage(messages.insurange),
              description: (
                <span>
                  <FormattedMessage
                    {...messages.children_insured}
                    values={defineFormatters({
                      amount: INSURANCE_AMOUNT,
                      insurance: (
                        <a href={INSURANCE_WEBSITE} rel={EXTERNAL_LINKS_REL} target="_blank">
                          {INSURANCE_NAME}
                        </a>
                      ),
                    })}
                  />
                  <br />
                  <br />
                  <FormattedMessage
                    {...messages.property_insured}
                    values={defineFormatters({
                      amount: PROPERTY_INSURANCE_AMOUNT,
                      insurance: (
                        <a href={PROPERTY_INSURANCE_WEBSITE} rel={EXTERNAL_LINKS_REL} target="_blank">
                          {PROPERTY_INSURANCE_NAME}
                        </a>
                      ),
                    })}
                  />
                </span>
              ),
              image: require('../../assets/benefits/brousver.svg?force_file'),
              image_anchor: 'bottom',
            },
            {
              id: 'support',
              title: formatMessage(messages.responsive_support),
              description: formatMessage(messages.live_support),
            },
            {
              id: 'safety',
              title: formatMessage(messages.safety),
              description: formatMessage(messages.all_sitters_are_checked),
              image: require('../../assets/benefits/robocop.svg?force_file'),
              image_anchor: 'bottom',
            },
          ]).map(b => (
            <div className={cn(classes.benefit, classes[`benefit_${b.id}`])} key={b.id}>
              {b.image && <img alt="" className={cn(classes.benefit_image, classes[`benefit_image_${b.image_anchor ?? 'bottom'}`])} src={b.image} />}
              <h3 className={cn(typo.landing_h3, b.image && (b.image_anchor ?? 'bottom') === 'top' ? classes.benefit_title_anchor_top : null)}>{b.title}</h3>
              <p className={cn(typo.subtext, b.image && (b.image_anchor ?? 'bottom') === 'bottom' ? classes.benefit_text_anchor_bottom : null)}>
                {b.description}
              </p>
            </div>
          ))}
        </div>
      </IntoView>
    </section>
  )

  const safetyBlock = (
    <section className={cn(landing.section, classes.safety_section)}>
      <h2 className={cn(landing.section_title)}>{formatMessage(messages.safety)}</h2>
      <Spacer height="2unit" />
      <h3 className={typo.landing_h3}>
        <FormattedMessage
          {...messages.all_sitters_are_thorougly_checked}
          values={defineFormatters({
            link: msg => (
              <a href="https://checkperson.ru/?a_aid=66647da918386&a_cid=9d7df67a" rel={EXTERNAL_LINKS_REL} target="_blank">
                {msg}
              </a>
            ),
          })}
        />
      </h3>
      <Spacer height="2unit" />
      <div className={classes.safety_items}>
        {ensureType<{ id: string; image: string; text: string }[]>([
          {
            id: 'direct',
            image: require('../../assets/safety/check_green.svg?force_file'),
            text: formatMessage(messages.documents_validation),
          },
          {
            id: 'reliable',
            image: require('../../assets/safety/shield.svg?force_file'),
            text: formatMessage(messages.legal_check),
          },
          {
            id: 'stats',
            image: require('../../assets/safety/check_blue.svg?force_file'),
            text: formatMessage(messages.finance_scoring),
          },
        ]).map(b => (
          <div className={cn(classes.safety_item)} key={b.id}>
            <img alt="" src={b.image} />
            <h3 className={typo.landing_h3}>{b.text}</h3>
          </div>
        ))}
      </div>
    </section>
  )

  const sittersBlock = (
    <section className={cn(landing.section)}>
      <h2 className={cn(landing.section_title)}>
        {formatMessage(messages.our_sitters)} {sitterGallery.controls}
      </h2>
      <Spacer height="2unit" />
      {sitterGallery.gallery}
      <Spacer height="3unit" />
      {renderActionButton({ section: 'sitters' })}
    </section>
  )

  const sitterFeaturesBlock = (
    <section className={cn(landing.section)}>
      <h2 className={cn(landing.section_title)}>{formatMessage(messages.babysitter_can)}</h2>
      <Spacer height="2unit" />
      <IntoView>
        <div className={cn(classes.benefits, classes.sitter_benefits)}>
          {ensureType<{ id: string; image?: string; image_anchor?: 'top' | 'bottom'; description: ReactNode }[]>([
            {
              id: 'sit_when_you_not_at_home',
              description: formatMessage(messages.sit_when_you_not_at_home),
              image: require('../../assets/benefits/flower.svg?force_file'),
            },
            {
              id: 'get_child_to_school',
              description: formatMessage(messages.get_child_to_school),
            },
            {
              id: 'get_child_from_school',
              description: formatMessage(messages.get_child_from_school),
            },
            {
              id: 'help_with_celebration',
              description: formatMessage(messages.help_with_celebration),
              image: require('../../assets/benefits/crazy_star.svg?force_file'),
            },
            {
              id: 'entertain_in_mall',
              description: formatMessage(messages.entertain_in_mall),
            },
            {
              id: 'get_child_to_relatives',
              description: formatMessage(messages.get_child_to_relatives),
            },
            {
              id: 'entertain_when_you_are_busy',
              description: formatMessage(messages.entertain_when_you_are_busy),
              image: require('../../assets/benefits/tunes.svg?force_file'),
            },
          ]).map(b => (
            <div className={cn(classes.benefit, classes[`sitter_benefit_${b.id}`])} key={b.id}>
              {b.image && <img alt="" className={cn(classes.benefit_image, classes[`benefit_image_${b.image_anchor ?? 'bottom'}`])} src={b.image} />}
              <h3 className={cn(typo.landing_h3, b.image && (b.image_anchor ?? 'bottom') === 'bottom' ? classes.benefit_text_anchor_bottom : null)}>
                {b.description}
              </h3>
            </div>
          ))}
        </div>
      </IntoView>
    </section>
  )

  const pressBlock = useMemo(() => {
    return (
      <section className={cn(landing.section)} id="press-about-us">
        <h2 className={cn(landing.section_title)}>
          {formatMessage(messages.press_about_us)} {pressGallery.controls}
        </h2>
        <Spacer height="2unit" />
        <div>{pressGallery.gallery}</div>
        <Spacer height="2unit" />
        <div className={classes.quote}>
          <h2 className={cn(classes.quote_title)}>«Можно ребенка просто любить и помогать ему встать на ноги»</h2>
          <Spacer height="1unit" />
          <p className={cn(classes.quote_author)}>Людмила Петрановская, семейный психолог, писатель</p>
          <Spacer height="1unit" />
          <p className={cn(classes.quote_text)}>о том нужны ли материнские жертвы и кто такой бебиситтер</p>
          <Spacer height="2unit" />
          <span className={cn(typo.subtext, classes.quote_link)}>
            {formatMessage(messages.read)} <InlineIcon icon="chevron-right" />
          </span>
          <a className={classes.overlay_link} href={PRESS_ARTICLES.find(a => a.id === 'thesymbol')!.link} rel={EXTERNAL_LINKS_REL} target="_blank">
            {' '}
          </a>
        </div>
        <Spacer height="3unit" />
        {renderActionButton({ section: 'press' })}
      </section>
    )
  }, [formatMessage, pressGallery.controls, pressGallery.gallery, renderActionButton])

  const clientsBlock = useMemo(() => {
    const clients: { id: string; name: string; logo: string; size: [width: number, height: number] }[] = [
      {
        id: 'yandex',
        name: 'Yandex',
        logo: require('@app/assets/clients/yandex.svg?force_file'),
        size: [367, 106],
      },
      {
        id: 'mozart_for_kids',
        name: 'Mozart for Kids',
        logo: require('@app/assets/clients/mozart_for_kids.svg?force_file'),
        size: [129, 62],
      },
      {
        id: 'stereoleto',
        name: 'Stereoleto',
        logo: require('@app/assets/clients/stereoleto.svg?force_file'),
        size: [173, 21],
      },
      {
        id: 'parents_ru',
        name: 'Parents.ru',
        logo: require('@app/assets/clients/parents_ru.png?force_file'),
        size: [171, 36],
      },
      {
        id: 'self_mama',
        name: 'Self Mama',
        logo: require('@app/assets/clients/self_mama.png?force_file'),
        size: [107, 73],
      },
      {
        id: 'seasons',
        name: 'Seasons',
        logo: require('@app/assets/clients/seasons.svg?force_file'),
        size: [161, 24],
      },
      {
        id: 'beat',
        name: 'Beat Festival',
        logo: require('@app/assets/clients/beat.svg?force_file'),
        size: [294, 247],
      },
      {
        id: 'changeonelife',
        name: 'Измени одну жизнь',
        logo: require('@app/assets/clients/changeonelife.svg?force_file'),
        size: [84, 96],
      },
      {
        id: 'kids_rock',
        name: 'Kids Rock Fest',
        logo: require('@app/assets/clients/kids_rock.png?force_file'),
        size: [46, 52],
      },
      {
        id: 'mycareer',
        name: 'Моя Карьера',
        logo: require('@app/assets/clients/mycareer.png?force_file'),
        size: [147, 54],
      },
      {
        id: 'podarizhizn',
        name: 'Подари жизнь',
        logo: require('@app/assets/clients/podarizhizn.svg?force_file'),
        size: [272, 59],
      },
    ]

    const multipliers = {
      kids_rock: 0.5,
      changeonelife: 0.6,
      podarizhizn: 1.2,
      stereoleto: 1.4,
    }

    return (
      <section className={cn(landing.section)}>
        <h2 className={cn(landing.section_title)}>{formatMessage(messages.our_clients)}</h2>
        <Spacer height="2unit" />
        <div className={classes.clients_items}>
          {clients.map(a => {
            const base = 170 / a.size[0]
            const mlt = multipliers[a.id] ?? 1
            return (
              <img
                alt={a.name}
                className={classes.press_item_image}
                key={a.id}
                src={a.logo}
                style={{ width: `${a.size[0] * base * mlt}px`, height: `${a.size[1] * base * mlt}px` }}
              />
            )
          })}
        </div>
      </section>
    )
  }, [formatMessage])

  const faqBlock = useMemo(() => {
    return (
      <section className={cn(landing.section)}>
        <h2 className={cn(landing.section_title)}>{formatMessage(messages.answer_the_questions)}</h2>
        <Spacer height="2unit" />
        <FaqAccordion questions={questions} style={AccordionStyleFAQ.instance} />
        <Spacer height="3unit" />
        <IntoView>
          <div className={Typography.Centered}>
            <Button component={Link} eventName="landing_parent.faq" href="/faq" type={ButtonTypePrimary.instance}>
              {formatMessage(messages.all_answers_ans_questions)}
            </Button>
          </div>
        </IntoView>
        <Spacer height="2unit" />
      </section>
    )
  }, [formatMessage, questions])

  return (
    <LayoutMilk flex={false} offset={true} page="landing">
      <section className={cn(landing.section_wide, classes.top)}>
        {blueBlock}
        {titleImage}
        {searchButton}
        {academyButton}
      </section>
      {tbankReviewBlock}
      {howItWorksBlock}
      {downloadAppBlock}
      {benefitsBlock}
      {safetyBlock}
      {sittersBlock}
      {sitterFeaturesBlock}
      {pressBlock}
      {clientsBlock}
      {faqBlock}
    </LayoutMilk>
  )
}

LandingParent.fetchData = async ({ routerState, store }) => {
  if (routerState.serverInitial) {
    store.dispatch(registerPreload(__webpack_module__.id))
  }
  if (routerState.browserInitial) return

  await Promise.all([
    updateTaskValue(store.dispatch(getSectionFaqTask('parents/landing'))),
    store.dispatch(getStatistics()).then(assertApiActionResponse('Statistics fetch failed')),
    store.dispatch(getTbankReviewsSummary()).then(assertApiActionResponse("Can't get T-Bank review data")),
  ])
}

const useShouldLogin = () => {
  const dispatch = useAppDispatch()
  const { location } = useRouter()

  useEffect(() => {
    const abort = new AbortController()
    const access_token = dispatch(getState(sessionSelector)).access_token
    const query = queryString.parse(location.search, { parseNumbers: false })
    const shouldLogin = !!query.login

    if (!shouldLogin) return
    ;(async () => {
      const next = (query.next! as string) || '/'
      new AnalyticsEvent('should_login', {
        type: 'landing_parent',
        access_token_end: access_token ? access_token.substring(access_token.length - 8) : null,
      }).sendAmplitude()

      const { wrapWithLoginHandler } = await dispatch(withProgress(import('@app/components/Login/loginHandler')))
      if (abort.signal.aborted) return

      dispatch(getMount())!.push(
        IMPORT_MAP.modals.LoginModal().then(m => m.LoginModal),
        {
          props: {
            onLogin: wrapWithLoginHandler(_result => (_dispatch, _getState, { router }) => {
              router!.history.replace(next)
            }),
          },
        }
      )
    })()

    return () => {
      abort.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

const versionDataSelector = createSelector([landingVersionSelector, profileRegionSelector, intlSelector], (version, region, { formatMessage }) =>
  new LandingData(formatMessage, region, version).getData()
)

const metaSelector = createSelector([versionDataSelector], versionData => ({
  title: versionData.meta_title,
  description: versionData.description,
  keywords: versionData.keywords,
  custom: ensureType<Record<string, string>[]>([
    {
      name: 'apple-itunes-app',
      content: `app-id=${APP_INFO.parent.ios.id}`,
    },
    {
      name: 'google-play-app',
      content: `app-id=${APP_INFO.parent.android.id}`,
    },
  ]),
  data: {
    google_app_description: {
      id: APP_INFO.parent.android.id,
      label: APP_INFO.parent.android.title,
      url: APP_INFO.parent.android.url,
      image: APP_INFO.parent.android.image,
      developer: APP_INFO.parent.android.developer,
    },
  },
}))

const messages = defineMessages({
  completed_requests_since: '{completed} {completed, plural, one {выполненный заказ} two {выполненных заказа} other {выполненных заказов}} с {year} года',
  find_a_sitter: 'Найти бебиситтера',
  online_lessons: 'Онлайн занятия',
  based_on: 'На основании {count} {count, plural, one {отзыва} two {отзывов} other {отзывов}}',
  rating_based_on_tbank: '{rating} рейтинг на основании отзывов Т-Банка',

  how_it_works: 'Как это работает',
  sitter_search: 'Поиск ситтера',
  choose_a_profile: 'Выбирайте анкету или разместите объявление, и ситтеры сами будут откликаться',
  making_an_order: 'Оформление заказа',
  discuss_in_chat: 'Обсудите детали с ситтером в чате и оформите заказ',
  order: 'Заказ',
  child_passing_time_with_fun: 'Ребёнок классно проводит время, а вы заняты своими деламии',
  payment: 'Оплата',
  only_after_the_order: 'Только после заказа вы оплачиваете услуги бебиситтера и услуги Kidsout',

  also_apk: 'Вы также можете скачать <apk>APK файл</apk> и установить приложение на любое Android устройство',

  benefits: 'Преимущества',
  agreements_without_intermediates: 'Договорённости без посредников',
  discuss_directly: 'Вы договариваетесь с бебиситтером напрямую',
  reliable_sitters: 'Надёжные бебиситтеры',
  all_sitters_are_trained: 'Все  бебиситтеры проходят собеседование и обучение с командой психологов',
  work_statistics: 'Показатели работы',
  statistics_are_kept: 'Вся статистика по качеству работы ситтера хранится в его профиле',
  payment_on_completion: 'Оплата по факту',
  you_pay_only_after_completion: 'Вы оплачиваете заказ только после его выполнения, и если вы довольны, переходите на подписку',
  insurange: 'Страхование',
  children_insured: 'Дети и имущество на заказах застрахованы на {amount} в {insurance}',
  property_insured: 'Ответственность застрахована на {amount} в {insurance}',
  responsive_support: 'Отзывчивая поддержка',
  live_support: 'Живая поддержка Kidsout Доступна 11/7',
  safety: 'Безопасность',
  all_sitters_are_checked: 'Все бебиситтеры проходят проверку документов на благонадёжность по 21 параметру, включая судебные дела и задолженности',

  all_sitters_are_thorougly_checked: 'Все наши бебиситеры проходят проверку безопасности. Вы можете проверить <link>свою няню тут.</link>',
  documents_validation: 'Валидация документов',
  legal_check: 'Проверка судимости',
  finance_scoring: 'Финансовый скоринг',

  our_sitters: 'Наши бебиситтеры',

  babysitter_can: 'Бебиситтер может',
  sit_when_you_not_at_home: 'Посидеть с ребенком, пока вас нет дома',
  get_child_to_school: 'Отвезти ребенка на занятия, к репетитору, в спортивную секцию',
  get_child_from_school: 'Проводить ребенка домой из школы, или сада',
  help_with_celebration: 'Помочь с детьми на дне рождения, семейном празднике',
  entertain_in_mall: 'Развлечь ребенка в торговом или спортивном центре, пока вы заняты',
  get_child_to_relatives: 'Отвезти ребенка к бабушкам или другим родственникам',
  entertain_when_you_are_busy: 'Отвлечь ребенка, пока вы заняты домашними делами',

  press_about_us: 'СМИ о нас',
  read: 'Читать',

  our_clients: 'Наши клиенты',

  answer_the_questions: 'Отвечаем на вопросы',
  all_answers_ans_questions: 'Все вопросы и ответы',

  rating: 'Рейтинг',
  of_tbank: 'Т-Банка',
})
